<template>
  <div>
    <p>
      TÜRKİYE İHRACATÇILAR MECLİSİ (“<b>TİM</b>”) olarak, Birliklere kayıtlı
      İhracatçı Firmalar ve Gümrük müşavirlerinin işlemlerinde maksimum kolaylık
      ve çabukluk sağlanması, bilgisayar ve network teknolojilerinin en yeni
      yeteneklerinden, en üst seviyede faydalanarak iş yükünün azaltılması
      amacıyla online işlemler adı altında bir proje başlattık.
    </p>
    <p>
      Bu proje kapsamında kişisel verilerinizi 07.04.2016 tarihinde yayımlanarak
      yürürlüğe giren 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”,
      “Kanun”) ve Kanun’a bağlı yürürlüğe koyulan ve koyulacak ikincil
      düzenlemeleri (yönetmelik, tebliğ, genelge) ve Kişisel Verileri Koruma
      Kurul’u tarafından alınmış ve alınacak bağlayıcı nitelikteki kararları
      uyarınca; gerekli özen ve hassasiyeti göstererek ve bu kanun kapsamında
      “<b>veri sorumlusu</b>” sıfatıyla işlemekteyiz.
    </p>
    <p><b>VERİ SORUMLUSUNA İLİŞKİN BİLGİLER</b></p>
    <p>
      KVKK uyarınca işbu aydınlatma metni uyarınca işlenecek verilerin sorumlusu
      <b>TÜRKİYE İHRACATÇILAR MECLİSİ (“TİM”)</b> ’ dir.
    </p>
    <p><b>KİŞİSEL VERİLERİ İŞLEME AMACI</b></p>
    <p>Kişisel verileriniz <b>TİM</b> tarafından şu amaçlarla işlenmektedir:</p>
    <p>Birliklere kayıtlı İhracatçı Firmalar ve Gümrük müşavirlerinin,</p>
    <ul>
      <li>
        Bağlı olduğu birliklerden manuel yolla aldığı hizmetleri online üyelik
        oluşturarak, online olarak almasını sağlayan bir sisteme giriş
        yapmalarının sağlanması.
      </li>
      <li>
        Kendilerine ait üyelik belgeleri alabilmek, vekaletname yükleyebilmek,
        kendilerine ait bilgileri güncelleyebilmek, yaptığı ihracat verilerine
        ait rapor alabilmelerinin sağlanması.
      </li>
    </ul>
    <p><b>KİŞİSEL VERİLERİN KİMLERE VE HANGİ AMAÇLARLA AKTARILABİLECEĞİ</b></p>
    <p>
      Kişisel verileriniz yukarıda sayılan amaçlar dahilinde; yurt içindeki
      doğrudan veya dolaylı iştiraklerimiz ile ortaklarımızla, sözleşmeli iş
      ortaklarımıza, tedarikçilerimize, gerekmesi halinde hizmet alınan üçüncü
      kişilerle iş süreçlerinin devamı için paylaşımın gerekli olduğu diğer kişi
      ve kuruluşlarla, kanunen yetkili kamu kurumları ve özel kişilere,
      faaliyetlerimizi yürütmek amaçları ile Kanun’un 8. ve 9. maddelerinde
      belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde
      belirtilen amaçlarla sınırlı olarak aktarılabilecektir.
    </p>
    <p>
      Üyelerin ekranda yaptığı bilgi güncelleme işlemleri TİM altyapısında yer
      alan e-birlik sistemi veritabanlarında saklanacaktır. Üyelerin yine bu
      ekrandan alacağı rapor ve bilgilerde yine bu sistem üzerinden
      karşılanmaktadır.
    </p>
    <p><b>KİŞİSEL VERİLERİN TOPLANMA YÖNTEMLERİ VE HUKUKİ SEBEPLERİ</b></p>
    <p>
      <b>TİM</b> tarafından kişisel verileriniz TİM internet sayfasında yer alan
      ONLİNE İŞLEMLER sistemine üyelik amacı ile sizden istenen verilerin ve
      belgelerin sisteme girişini yapmanız sayesinde toplanmaktadır.
    </p>
    <p>
      Üyelik onay işlemi amacı ile girilen veriler ve eklenen belgeler üyenin
      bağlı olduğu birlik onay yetkilisi tarafından görülmektedir
    </p>
    <p>
      Üyelerin online işlemler sistemi üzerinden yaptıkları bilgi güncelleme
      işlemleri, alacakları raporlar ve bilgiler TİM altyapısında yer alan e-
      birlik sistemi veritabanlarında saklanmaktadır.
    </p>
    <p>Bu kapsamda işlenen kişisel verileriniz şunlardır:</p>
    <ul>
      <li>İhracatçı firma yetkilisi veya Gümrük Müşaviri olduğu bilgisi</li>
      <li>İhracatçı firma veya Gümrük Müşaviri vergi numarası</li>
      <li>
        Ad - Soyad, T.C. Kimlik No, E-posta adresi ve cep telefonu bilgisi
      </li>
      <li>
        Firma yetkilisinin firmasına ait imza sirkülerindeki veriler:
        <ul>
          <li>Ad- Soyad ve imza bilgisi</li>
          <li>Firma içerisindeki statü bilgisi</li>
        </ul>
      </li>
      <li>
        Üyelik dilekçesi içerisinde yer alan veriler:
        <ul>
          <li>Ad-Soyad, kaşe, imza bilgisi</li>
        </ul>
      </li>
    </ul>
    <p>
      Yukarıda sayılanlar ve benzeri kişisel verileriniz KVKK md.5/1,2/a,ç,e,f
      kapsamında işlenmektedir.
    </p>
    <p><b>VERİ SAHİPLERİNİN KANUN KAPSAMINDAKİ HAKLARI</b></p>
    <p>Dilediğiniz zaman <b>TİM</b>’e başvurarak kişisel verilerinizin;</p>
    <ul>
      <li>
        İşlenip işlenmediğini, işlenme amacını ve amacına uygun kullanıp
        kullanılmadığı öğrenebilir ve işlenmiş ise bu konuda bilgi isteyebilir,
      </li>
      <li>
        Kanun’a uygun olarak yurt içinde ve yurt dışında bilgilerinizin
        paylaşıldığı üçüncü kişileri öğrenebilir,
      </li>
      <li>
        Bilgilerinizin eksik ya da hatalı işlendiğini düşünüyorsanız
        düzeltilmesini isteyebilir,
      </li>
      <li>
        Kanunun 7. maddesinde öngörülen şartlar çerçevesinde bilgilerinizin
        silinmesini ya da yok edilmesini talep edebilir,
      </li>
      <li>
        Bilgilerinizin aktarıldığı üçüncü kişilere (c) ve (d) bentlerinde
        belirtilen taleplerinizin bildirilmesini ve aynı işlemleri
        gerçekleştirmelerini isteyebilir,
      </li>
      <li>
        Bilgilerinizin, otomatik sistemler ile analiz edilmesi nedeniyle
        aleyhinize bir sonucun ortaya çıkmasına itiraz edebilir veya kanuna
        aykırı olarak kaydedildiğini veya kullanıldığını düşünüyorsanız ve bu
        sebeple zarara uğramışsanız zararın giderilmesini isteyebilirsiniz.
      </li>
    </ul>
    <p>
      Bu amaçlarla yaptığınız başvurunun ek bir maliyet gerektirmesi durumunda,
      Kişisel Verileri Koruma Kurulu tarafından çıkarılan Veri Sorumlusuna
      Başvuru Usul ve Esasları Hakkında Tebliğ ile belirlenen ücreti ödemeniz
      söz konusu olacaktır. Başvurunuza yazılı cevap verilmesi halinde ilk 10
      (on) sayfaya kadar ücret alınmayacak, 10 (on) sayfanın üzerindeki her
      sayfa için 1 TL işlem ücreti alınacaktır. Başvurunuza verilecek cevabın
      CD, flash bellek gibi bir kayıt ortamında verilmesi halinde ise, kayıt
      ortamının maliyeti kadar ücret tarafınıza yansıtılacaktır.
    </p>
    <p>
      Başvurunuzda yer alan talepleriniz, talebin niteliğine göre en kısa sürede
      ve en geç 30 (otuz) gün içinde sonuçlandırılacaktır.
    </p>
    <p>
      Kanun kapsamındaki haklarınızdan yararlanmak için başvurularınızı, Türkçe
      olmak kaydıyla yazılı olarak <b>TİM</b>’e iletebilir, detaylı bilgi almak
      için Kişisel Verileri Koruma Kurumu’nun internet sayfasını ziyaret
      edebilirsiniz.
    </p>
    <p><u>Başvurularınızı; </u></p>
    <p>
      Kanun’un 13. maddesinin 1. fıkrası gereğince yukarıda belirtilen
      haklarınızı kullanmakla ilgili talebinizi yazılı olarak veya Kişisel
      Verilerin Korunması Kurulu’nun belirlediği diğer yöntemlerle iletmeniz
      gerekmektedir.
    </p>
    <p>
      Kişisel veri sahibi olarak sahip olduğunuz ve yukarıda belirtilen
      haklarınızı kullanmak için yapacağınız ve kullanmayı talep ettiğiniz hakka
      ilişkin açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun açık ve
      anlaşılır olması, talep ettiğiniz konunun şahsınız ile ilgili olması veya
      başkası adına hareket ediyor iseniz bu konuda noter tarafından
      tasdiklenmiş özel vekâletnamenizi ibraz etmeniz gerekecektir.
    </p>
    <p>
      Başvurularınızda, ad-Soyad, imza, T.C. kimlik numarası, ikamet veya işyeri
      adresi, e-posta adresi, telefon ve faks numarası, talep konusu
      unsurlarının bulunması “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında
      Tebliğ” uyarınca zorunludur. Söz konusu unsurları barındırmayan başvurular
      <b>TİM</b> tarafından reddedilecektir.
    </p>
    <p>
      <b>TİM</b>’in işbu aydınlatma metninde Kanun’dan, ikincil düzenlemelerden
      ve Kurul kararlarından doğan sebeplerle değişiklik yapma hakkı her zaman
      saklıdır. Aydınlatma metninde yapılacak değişiklikler ve güncel metin
      tarafınıza tebliğ edildiği tarih itibariyle derhal geçerlilik
      kazanacaktır.
    </p>
    <div style="text-align:right;padding-top:35px;">
      <p><b>TÜRKİYE İHRACATÇILAR MECLİSİ</b></p>
      <p style="">
        Dış Ticaret Kompleksi Çobançeşme Mevkii Sanayi Cd. <br />
        B Blok Kat:9, Yenibosna, İstanbul<br />
        0212 454 04 90 / 0212 454 04 91
      </p>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
