<template>
  <app-card-wizard
    class="review"
    titleClass="turquoise"
    v-for="(section, index) in info"
    :key="section"
    :title="section.title"
    :showProgress="index === 0"
  >
    <template #description v-if="section.description">
      <div v-html="section.description"></div>
    </template>
    <div class="form-section mt-5">
      <div class="form-group" v-for="item in section.items" :key="item">
        <template v-if="item.isSubTitle">
          <h4 v-html="item.label" class="mt-5"></h4>
        </template>
        <template v-else>
          <label class="control-label">{{ item.label }}</label>
          <div class="item-value" v-html="item.value"></div>
        </template>
      </div>
      <template v-if="section.editable">
        <button
          type="button"
          class="btn btn-outline-turquoise"
          @click="onEdit(section.name)"
        >
          <i class="far fa-pencil mr-3" style="margin-left:-7px;"></i
          ><span>Değişiklik Yap</span>
        </button>
      </template>
    </div>
  </app-card-wizard>
  <app-card-wizard title="Onay" :showProgress="false">
    <template #description>
      <p>
        İhracat ailesine katılmanıza çok az kaldı. Yukarıdaki bilgiler doğru
        ise başvuruyu tamamla butonuna tıklayabilirsiniz. Başvurunuz
        alındıktan sonra başta belirtmiş olduğunuz e-posta adresiniz olan
        <strong>{{ applicationData.email }}</strong>
        adresine bundan sonra yapmanız gereken işlemleri ileteceğiz.
      </p>
    </template>
    <div class=" mt-5">
      <div class="d-flex align-items-center" v-if="!hasOnlineMembership">
        <p-checkbox
          id="createUserAccount"
          v-model="createUserAccount"
          :binary="true"
        ></p-checkbox>
        <label
          for="createUserAccount"
          style="margin-bottom:0; margin-left:10px;"
        >
          TİM - Online İşlemler Üyeliğimi de başlatmak istiyorum
        </label>
      </div>
      <div class="d-flex align-items-center mt-2">
        <p-checkbox
          id="approve"
          v-model="isApproved"
          :binary="true"
        ></p-checkbox>
        <label for="approve" style="margin-bottom:0; margin-left:10px;"
          ><a href="#" target="_blank" @click.prevent="openGdpr"
            >KVKK Aydınlatma Metni ve Gizlilik Bildirimini</a
          >
          okudum kabul ediyorum</label
        >
      </div>
    </div>

    <div class="d-flex mt-4">
      <button
        type="button"
        :class="[
          'btn btn-primary-dark d-flex align-items-center',
          { 'btn-loading': isLoading }
        ]"
        @click="onSubmit"
        :disabled="!isApproved || isLoading"
      >
        <span>BAŞVURUYU TAMAMLA</span>
      </button>
      <button v-if="false" type="button" class="btn btn-outline-info ml-3">
        Önceki Adıma Geri Dön
      </button>
    </div>
  </app-card-wizard>
  <p-dialog
    header="KVKK Aydınlatma Metni"
    v-model:visible="isGdprVisible"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <app-gdpr></app-gdpr>
    </div>

    <template #footer>
      <button class="btn btn-primary" @click="closeGdpr" autofocus>
        KAPAT
      </button>
    </template>
  </p-dialog>
</template>

<script>
import { settings } from "../shared";
import apiClient from "../services";
import PCheckbox from "primevue/checkbox";
import PDialog from "primevue/dialog";
import AppGdpr from "../components/UI/KVKK.vue";

export default {
  components: { PCheckbox, PDialog, AppGdpr },
  data() {
    return {
      isGdprVisible: false,
      isApproved: false,
      isLoading: false,
      currentView: 0,
      createUserAccount: true
    };
  },
  methods: {
    onEdit(path) {
      this.goTo(`/${path}/${this.applicationData.stateId}`);
    },
    openGdpr() {
      this.isGdprVisible = true;
    },
    closeGdpr() {
      this.isGdprVisible = false;
    },
    onSubmit() {
      if (this.isApproved) {
        this.isLoading = true;
        this.showPageLoader("Başvurunuz kaydediliyor...");
        this.updateApplicationData({
          fromReview: false,
          createUserAccount: this.createUserAccount
        });

        apiClient
          .saveApplication(this.applicationData)
          .then((response) => {
            if (response && response.success) {
              this.markStepAsCompleted(this.stepName);
              this.updateApplicationData({
                approvalRequest: {
                  code: response.data,
                  statusId: 0
                }
              });

              this.goTo("/result/" + this.applicationData.stateId);
            } else {
              this.$swal({
                icon: "error",
                title: "Başvuru Alınamadı!",
                html:
                  "Başvurunuz kaydedilirken bir hata oluştu. <br /><br />" +
                  response.message,
                confirmButtonText: "Tamam"
              });
            }
          })
          .catch((err) => {
            console.error(err);
            this.$swal({
              icon: "error",
              title: "Başvuru Alınamadı!",
              html:
                "Başvurunuz kaydedilirken beklenmedik bir hata oluştu. Lütfen daha sonra tekrar deneyin",
              confirmButtonText: "Tamam"
            });
          })
          .then(() => {
            this.isLoading = false;
            this.hidePageLoader();
          });
      }
    },
    getPhoneType(type) {
      switch (type) {
        case "gsm":
          return "Cep Telefonu";
        case "fax":
          return "Faks";
        default:
          return "Sabit Telefon";
      }
    }
  },
  computed: {
    hasOnlineMembership() {
      return this.applicationData.hasOnlineMembership;
    },
    stepName() {
      return "review";
    },
    info() {
      const d = this.applicationData;
      const sections = [];

      const infoItems = [
        {
          label: "Başvuru Türü",
          value: settings.registrationTypes[d.registrationType].title
        }
      ];

      if (!this.isBrokerRegistration) {
        infoItems.push({
          label: "Başvuru Yapılan Birlik",
          value: `<div>${d.selectedAssociation.associationName}</div><div class="text-muted small">${d.selectedAssociation.city}</div>`
        });
      }

      sections.push({
        title: "Başvuru Bilgileri",
        description: `<p class="mb-5">Lütfen aşağıdaki başvuru bilgilerini kontrol edin. Eğer bilgilerde herhangi bir yanlışlık var ise düzeltip başvurunuzu tamamlayabilirsiniz.</p>`,
        items: infoItems,
        showProgress: true
      });

      /* Başvuruyu yapan */
      sections.push({
        title: "Başvuruyu Yapan",
        editable: true,
        name: "applicant",
        items: [
          {
            label: "Adı Soyadı",
            value: d.applicant.fullName
          },
          {
            label: "T.C. Kimlik Numarası",
            value: d.applicant.identityNumber
          },
          {
            label: "E-posta Adresi",
            value: d.email || d.applicant.email
          },
          {
            label: "Cep Telefonu",
            value: d.applicant.gsm
          },
          {
            label: "Başvuran",
            value: (settings.applicantTypes[d.applicant.type] || {}).title
          }
        ]
      });

      /* Firma Bilgileri*/
      const companyInfo = {
        title: "Firma Bilgileri",
        editable: true,
        name: "company-info",
        items: [
          {
            label: "Firma Unvanı / Nevi",
            value: `<div>${d.companyInfo.companyName}</div><div class="small text-muted">${d.companyInfo.companyType.name}</div>`
          },
          {
            label: "Vergi No / Dairesi",
            value: `<div>${d.companyInfo.taxNumber}</div><div class="small text-muted">${d.companyInfo.taxOffice.name} ${d.companyInfo.taxOffice.type}</div>`
          },
          {
            label: "Mersis No",
            value: d.companyInfo.mersisNumber
          },
          {
            label: "Ticaret Sicil No / Müdürlüğü",
            value: `${d.companyInfo.tradeRegistrationNumber} <span class="text-muted">/</span> ${d.companyInfo.tradeRegistrationOffice}`
          },
          {
            label: "Konumu",
            value: d.companyInfo.companyCategory.join(", ")
          }
        ]
      };

      if (d.exportType === "hizmet") {
        companyInfo.items.push({
          label: "Sektör",
          value: d.sector || d.companyInfo.sector
        });

        companyInfo.items.push({
          label: "Bir önceki yıl brüt satış tutarı",
          value: d.companyInfo.grossSales + " TL"
        });
      }

      sections.push(companyInfo);

      const contacts = d.contacts;

      if (contacts.length > 0) {
        const sContacts = {
          title: "Firma Yetkilileri",
          editable: true,
          name: "company-contacts",
          items: []
        };

        contacts.forEach((c) => {
          if (!c.sameAsPrevious) {
            sContacts.items.push({
              label: c.role.name,
              isSubTitle: true
            });

            sContacts.items.push({
              label: "Ad Soyad",
              value: c.fullName
            });

            sContacts.items.push({
              label: "T.C. Kimlik Numarası",
              value: c.identityNumber
            });

            sContacts.items.push({
              label: "E-posta Adresi",
              value: c.email
            });

            sContacts.items.push({
              label: "Cep Telefonu",
              value: c.gsm
            });
          } else {
            sContacts.items.push({
              label: c.role.name,
              isSubTitle: true
            });

            sContacts.items.push({
              value: "Diğer yetkili ise aynı kişi"
            });
          }
        });

        sections.push(sContacts);
      }

      sections.push({
        title: "Kanuni Merkez",
        editable: true,
        name: "company-address-hq",
        items: [
          {
            label: "Adres",
            value: `${d.hqAddress.address} ${d.hqAddress.zipCode} ${d.hqAddress.county}, ${d.hqAddress.city}`
          },
          {
            label: "Telefon ve Faks Numaraları",
            value: d.hqAddress.phones
              .filter((x) => !!x.phone)
              .map(
                (x) =>
                  `<div class="d-flex" style="font-size:15px;"><div style="min-width:110px; color:#666;">${this.getPhoneType(
                    x.type
                  )}</div><div class="value"> ${x.phone}</div></div>`
              )
              .join("")
          },
          {
            label: "E-posta Adresi",
            value: d.hqAddress.email || "Belirtilmemiş"
          },
          {
            label: "Kayıtlı E-posta Adresi (KEP)",
            value: d.hqAddress.kep || "Belirtilmemiş"
          },
          {
            label: "Web Sitesi",
            value: d.hqAddress.webSite || "Belirtilmemiş"
          }
        ]
      });

      if (d.branchOfficeAddress) {
        sections.push({
          title: d.branchOfficeAddress.city + " Şubesi",
          editable: true,
          name: "company-address-branch",
          items: [
            {
              label: "Adres",
              value: `${d.branchOfficeAddress.address} ${d.branchOfficeAddress.zipCode} ${d.branchOfficeAddress.county}, ${d.branchOfficeAddress.city}`
            },
            {
              label: "Telefon ve Faks Numaraları",
              value: d.branchOfficeAddress.phones
                .filter((x) => !!x.phone)
                .map(
                  (x) =>
                    `<div class="d-flex" style="font-size:15px;"><div style="min-width:110px; color:#666;">${this.getPhoneType(
                      x.type
                    )}</div><div class="value"> ${x.phone}</div></div>`
                )
                .join("")
            },
            {
              label: "E-posta Adresi",
              value: d.branchOfficeAddress.email || "Belirtilmemiş"
            }
          ]
        });
      }

      sections.push({
        title: "Sirküler Bildirim Ayarları",
        description: `<div class="mb-4">Sirküler gönderilecek e-posta adresi listesini kontrol ediniz.</div>`,
        editable: true,
        name: "notification-settings",
        items: [
          {
            label: "Sirküler Gönderilecek E-posta Adresleri",
            value: d.notificationSettings
              .filter((e) => e.sirkuler)
              .map((e) => `<div class="py-1">${e.email}</div>`)
              .join("")
          }
        ]
      });

      return sections;
    }
  },
  beforeMount() {
    this.markStepAsInCompleted(this.stepName);

    this.updateApplicationData({
      fromReview: true
    });
  }
};
</script>

<style scoped lang="scss">
@import "../scss/_mixins.scss";
@import "../scss/_variables.scss";

.p-dialog {
  background: #fff;
}

.p-dialog .p-dialog-content {
  border-radius: none !important;
}

.review {
  font-size: 18px;
  color: #000;

  .form-group {
    margin-bottom: 1.6rem;

    label {
      margin-bottom: 0;
      color: #777;
      font-weight: $font-weight-normal;
      text-transform: uppercase;
      font-size: 12px;
    }

    .item-value {
      line-height: 22px;
    }
  }
}
</style>
